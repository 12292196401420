export default {
    slide_menu: [
        {
            title: '京津冀',
            city: '北京',
            children: [{title: '北京首都'}, {title: '北京大兴'}, {title: '天津滨海'}],
        },
        {
            title: '长三角',
            city: '上海',
            children: [{ title: '浦东国际机场'}, { title: '虹桥机场'}],
        },
        {
            title: '珠三角',
            city: '广州',
            children: [{title: '宝安国际机场'}, {title: '南头直升机场'}, {title: '沙堤机场'}]
        }
    ],
    iconfont_map: {

            "00":"icon-ziyuan",


            "01":"icon-duoyun",


            "02":"icon-yin",


            "03":"icon-zhenyu",


            "04":"icon-leizhenyu",


            "05":"icon-leizhenyubingbao",


            "06":"icon-yujiaxue",


            "07":"icon-xiaoyu",


            "08":"icon-zhongyu",


            "09":"icon-dayu1",


            "10":"icon-baoyu",


            "11":"icon-tq_19dabaoyu",


            "12":"icon-dabaoyudaotedabaoyu",


            "13":"icon-zhenxue",


            "14":"icon-tq_29xiaoxue",


            "15":"icon-zhongxue",


            "16":"icon-daxue",


            "17":"icon-tq_35baoxue",


            "18":"icon-wu",


            "19":"icon-n-dongyu",


            "20":"icon-shachenbaozhuanhuan",


            "21":"icon-xiaodaozhongyu",


            "22":"icon-xiaodaozhongyu",


            "23":"icon-dadaobaoyu",


            "24":"icon-dabaoyudaotedabaoyu",


            "25":"icon-dadaobaoyu",


            "26":"icon-daxue",


            "27":"icon-daxue",


            "28":"icon-daxue",


            "29":"icon-fuchen",


            "30":"icon-tq_43yangsha",


            "31":"icon-shachengbao",


            "53": "icon-mai",

            "undefined": "icon-weather_no_result_big",
            "正在获取": "icon-weather_no_result_big",
    }
}
