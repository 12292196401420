import { request } from '@/utils/'

export const get_air_route_data = params => request.get('/api/air_route/', { params })


// 获取进离场空域数据
export const get_enter_leave_optimization_airspace_data = params => request.get('/api/enter_leave_optimization_airspace_data/', { params })


// 获取进离场航路优化的机场数据 侧边栏
export const get_enter_leave_class_data = params => request.get('/api/enter_leave_class_data/', { params })


// 获取航线数据
export const get_flight_data = params =>request.get('/api/enter_leave_flight/', { params })
