<template>
    <div :is="layout">
        <div class="d-flex">
            <div class="d-flex flex-column" style="width: 300px">
                <v-card style=" background: rgb(0, 0, 0, .1)">
                    <v-card-title>{{$t('air_route.details')}}</v-card-title>
                    <i style="font-size: 80px; position: absolute; right: 30px; top: 20px; color: #aaa; opacity: .4"
                       :class="`iconfont ${mock_data.iconfont_map[weather_info.wid]}`"></i>
                    <v-row class="pa-4 text-caption" style="user-select: none">
                        <v-col cols="6">
                            {{$t('air_route.current_region')}}: <span class="primary--text">{{slide_menu[slide_model] ? slide_menu[slide_model].main_city : $t('air_route.loading')}}</span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('air_route.temperature')}}: <span class="primary--text">{{weather_info.temperature}}℃</span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('air_route.weather')}}: <span class="primary--text">{{weather_info.info}}</span>
                        </v-col>
                        <!--                            <v-col cols="6">-->
                        <!--                                天气标识: <span class="primary&#45;&#45;text">{{weather_info.wid}}</span>-->
                        <!--                            </v-col>-->
                        <v-col cols="6">
                            {{$t('air_route.wind_direction')}}: <span class="primary--text">{{weather_info.direct}}</span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('air_route.wind_power')}}: <span class="primary--text">{{weather_info.power}}</span>
                        </v-col>
                        <v-col cols="6">
                            {{$t('air_route.air_quality')}}: <span class="primary--text">{{weather_info.aqi}}</span>
                        </v-col>
                    </v-row>
                </v-card>
                <v-slide-group v-model="slide_model" class="pa-1" center-active show-arrows>
                    <v-slide-item :key="`key=${index}`" v-for="(item, index) of slide_menu"
                                  v-slot="{ active, toggle }">
                        <v-card :color="active ? 'secondary lighten-4' : ''" @click="toggle" height="50" width="100"
                                class="ma-2">
                            <v-row align="center" justify="center" class="fill-height pt-6">
                                {{item.name}}
                            </v-row>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
                <v-list class="flex-grow-1" style="background: rgba(0,0,0,0)">
                    <v-subheader>{{$t('air_route.airport_list')}}{{airport_ids}}</v-subheader>
                    <v-list-item-group
                            color="primary"
                    >
                        <v-list-item
                                v-for="(item, i) in current_child_city"
                                :key="i"
                        >
                            <v-list-item-content>
                                <v-checkbox v-model="airport_ids" :value="item.id" class="fill-height" dense :label="item.name"></v-checkbox>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <v-divider></v-divider>
                <div class="pt-3">
                    <v-btn @click="reset_computed_dialog = true">{{$t('air_route.recalculate')}}</v-btn>
                </div>
            </div>
            <div style="height: calc(100vh - 100px); width: 100%" class="ml-3" id="cesiumContainer"></div>
            <div class="container__options">
                <!--            <v-btn color="primary" @click="draw(enter_data)">进场</v-btn>-->
                <!--            <v-btn style="margin-left: 10px" color="primary" @click="draw(leave_data)">离场</v-btn>-->
                <v-subheader>
                    {{$t('air_route.standard_procedure')}}
                </v-subheader>
                <v-divider></v-divider>
                <v-checkbox
                        :disabled="show_tracks"
                        v-model="checkbox"
                        :value="0"
                        :label='$t("air_route.approach")'
                ></v-checkbox>
                <v-checkbox
                        :disabled="show_tracks"
                        v-model="checkbox"
                        :value="1"
                        :label='$t("air_route.departure")'
                ></v-checkbox>
                <v-subheader>
                    {{$t('air_route.Optimized_program')}}
                </v-subheader>
                <v-divider></v-divider>
                <v-checkbox
                        :disabled="show_tracks"
                        v-model="checkbox"
                        :value="2"
                        :label='$t("air_route.approach")'
                ></v-checkbox>
                <v-checkbox
                        :disabled="show_tracks"
                        v-model="checkbox"
                        :value="3"
                        :label='$t("air_route.departure")'
                ></v-checkbox>
                <v-subheader>
                    {{$t('air_route.Other_Settings')}}
                </v-subheader>
                <v-divider></v-divider>
                <v-checkbox
                        v-model="auth_fly"
                        :label='$t("air_route.Free_Roaming_View")'
                ></v-checkbox>
                <v-checkbox
                        v-model="show_air"
                        :label='$t("air_route.display_the_airspace")'
                ></v-checkbox>
                <v-checkbox
                        v-model="show_obstacle"
                        :label='$t("air_route.show_obstacle")'
                ></v-checkbox>
                <v-checkbox
                        v-model="show_aircraft"
                        :disabled="show_tracks"
                        :label='$t("air_route.show_aircraft")'
                ></v-checkbox>
                <v-checkbox
                        v-model="show_tracks"
                        :label='$t("air_route.show_tracks")'
                ></v-checkbox>
                <v-btn @click="ok_preview" width="100%">{{$t('preview')}}</v-btn>
            </div>
        </div>
        <v-dialog v-model="reset_computed_dialog" width="800px">
            <v-card color="list">
                <v-card-title>{{$t('air_route.input_parameter')}}</v-card-title>
                <v-container class="pa-6">
                    <v-row>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.degree')" v-model="input_data.TO_pente_max" :label='$t("air_route.Maximum_climbing_angle")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.degree')" v-model="input_data.TO_pente_min" :label='$t("air_route.Minimum_climbing_angle")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.degree')" v-model="input_data.LD_pente_max" :label='$t("air_route.maximum_drop_angle")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.degree')"  v-model="input_data.LD_pente_min" :label='$t("air_route.Minimum_drop_angle")'></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.nm')"  v-model="input_data.minSepHor" :label='$t("air_route.Minimum_horizontal_interval")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.ft')"  v-model="input_data.minSepVet" :label='$t("air_route.Minimum_vertical_interval")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.nm')"  v-model="input_data.minMerPtsDis" :label='$t("air_route.point")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.nm')"  v-model="input_data.effectiveLength_sid" :label='$t("air_route.SID")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.nm')"  v-model="input_data.effectiveLength_star" :label='$t("air_route.STAR")'></v-text-field>
                        </v-col>


                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.degree')"  v-model="input_data.maxTurnAng_deg_sid" :label='$t("air_route.approach_tur")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field :suffix="$t('air_route.degree')"  v-model="input_data.maxTurnAng_deg_star" :label='$t("air_route.departure_tur")'></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field  v-model="input_data.objWeight_pathLength" :label='$t("air_route.Route_weighted")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.objWeight_treeWeight" :label='$t("air_route.Net_length")'></v-text-field>
                        </v-col>


                        <v-col cols="4">
                            <v-text-field  :suffix="$t('air_route.nm')" v-model="input_data.norm" :label='$t("air_route.grid")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.nodeExtendCnt" :label='$t("air_route.expansion")'></v-text-field>
                        </v-col>


                        <v-col cols="4">
                            <v-text-field  v-model="input_data.T_max" :label='$t("air_route.max_annealing_temperature")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.T_min" :label='$t("air_route.min_annealing_temperature")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.coolRate" :label='$t("air_route.Cooling_rate")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.iterNum" :label='$t("air_route.iterations")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.probability0" :label='$t("air_route.replacing_matrix")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.probability1" :label='$t("air_route.substitution")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  :suffix="$t('air_route.degree')" v-model="input_data.angleSeuil" :label='$t("air_route.thredetourshold")'></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field  v-model="input_data.arcStrSeuil" :label='$t("air_route.Arc_chord")'></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn>{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {get_air_route_data, get_enter_leave_class_data} from "../../api/air_route";
    import { get_flight_data } from "../../api/air_route";
    import {get_weather} from "../../api/weather";
    import { get_enter_leave_optimization_airspace_data } from "../../api/air_route"; // 获取进离场程序优化的空域数据
    // 获取模拟数据
    import mock_data from './mock/'

    export default {
        name: 'air_route',
        data: () => ({
            show_obstacle: false,   // 是否显示障碍物
            show_aircraft: false,   // 航路是否显示飞机指引
            show_tracks: false,     // 是否显示航迹
            label_data: [],
            input_data: {
                // 最小爬升角  double
                TO_pente_min: '4.5',
                // 最大爬升角  double
                TO_pente_max: '7.5',
                // 最小下降角  double
                LD_pente_min: '1',
                // 最大下降角  double
                LD_pente_max: '3',
                // 最小水平间隔  double
                minSepHor: '3',
                // 最小竖直间隔  double
                minSepVet: '1000',
                // 汇聚（发散）点间最小间隔  double
                minMerPtsDis: '5',
                // SID有效长度  int
                effectiveLength_sid: '70',
                // STAR有效长度  int
                effectiveLength_star: '70',
                // 进场最大转弯角度  double
                maxTurnAng_deg_sid: '45',
                // 离场最大转弯角度  double
                maxTurnAng_deg_star: '90',
                // 航路加权长权值  double
                objWeight_pathLength: '1',
                // 航路净长权值  double
                objWeight_treeWeight: '0',
                // 网格长度  int
                norm: '3',
                // 航路扩展方式  int
                nodeExtendCnt: '4',
                // 最大退火温度  double
                T_max: '100',
                // 最小退火温度  double
                T_min: '0.1',
                // 降温速率  double
                coolRate: '0.95',
                // 迭代次数  int
                iterNum: '100',
                // 替换矩阵的概率  double
                probability0: '0.5',
                // 替换点的概率  double
                probability1: '0.5',
                // 航路绕弯阈值  double
                angleSeuil: '180',
                // 弧弦比阈值  double
                arcStrSeuil: '1.5',
            },
            enter_data: [], // 进场数据
            leave_data: [], // 离场数据
            obstacle_data: [], // 障碍物数据
            viewer: null,
            layout: null,
            label_show: true,
            checkbox: [],
            reset_computed_dialog: false,
            auth_fly: true,
            show_air: false,
            slide_model: 0,
            air_port_space_data: [],
            airport_ids: [],
            // selectedItem: 0,
            weather_info: {
                aqi: '正在获取',
                direct: "正在获取",
                humidity: "正在获取",
                info: "正在获取",
                power: "正在获取",
                temperature: "正在获取",
                wid: ""
            },
            mock_data,
            slide_menu: []
        }),
        watch: {
            show_tracks(v) {
                if (v ) {
                    this.show_aircraft = false
                    this.checkbox = []
                }
            },
            // checkbox(v) {
            //     this.viewer.dataSources.removeAll()
            //     const customDataSource = new this.Cesium.CustomDataSource("customDataSource")
            //     for (let i of v) {
            //         this.change_checkbox(i, customDataSource)
            //     }
            //     this.viewer.dataSources.add(customDataSource)
            //     this.auth_fly && this.viewer.flyTo(customDataSource)
            // },
            // selectedItem() {
            //     this.get_weather()
            // },
            slide_model() {
                this.airport_ids = []
                this.get_weather()
            }
        },
        methods: {
            // 获取航线数据
            async get_flight_line_data(airport_id = false, customDataSource) {
                if (!airport_id) {
                    this.$toast({
                        title: this.$t('setting.warning'),
                        type: 'warning',
                        message: 'Error in obtaining flight route data'
                    })
                    throw new Error('Error in obtaining flight route data')
                    return
                }

                const res = await get_flight_data({airport_id, pagesize: 99999})
                let data =res.results
                const group_data = {}
                data = data.sort( (a,b) => {
                    return a.time < b.time
                })
                data.forEach(x => {
                    if (x.flight_number in group_data) {
                        group_data[x.flight_number].push(x)
                    }else {
                        group_data[x.flight_number] = [x]
                    }
                })


                const start_time = this.Cesium.JulianDate.fromDate(new Date('2021-11-01 00:00:00'));
                this.viewer.clock.startTime = start_time
                this.viewer.clock.currentTime = start_time
                this.viewer.clock.stopTime = this.Cesium.JulianDate.fromDate(new Date('2021-11-01 23:59:59'))
                this.viewer.timeline.zoomTo(start_time, this.Cesium.JulianDate.fromDate(new Date('2021-11-01 23:59:59')));// 循环执行,即为2，到达终止时间，重新从起点时间开始
                this.viewer.clock.clockRange = this.Cesium.ClockRange.LOOP_STOP;
                this.viewer.clock.multiplier = 50

                for (let i in group_data) {
                    const item = group_data[i];
                    // Process each flight information
                    const property = new this.Cesium.SampledPositionProperty();
                    let start_time = null
                    let end_time = null
                    for (let ii in item) {
                        const time = this.Cesium.JulianDate.fromDate(new Date(item[ii].time * 1000), new this.Cesium.JulianDate())
                        const position = this.Cesium.Cartesian3.fromDegrees(parseFloat(item[ii].lng), parseFloat(item[ii].lat), parseFloat(item[ii].height) * 0.3048)
                        if (ii <= 0) {
                            start_time = time
                        }
                        if (ii >= item.length - 1) {
                            end_time = time
                        }
                        property.addSample(time, position)
                    }
                    const orientation = new this.Cesium.VelocityOrientationProperty(property)
                    customDataSource.entities.add(new this.Cesium.Entity({
                        orientation,
                        position: property,
                        availability: new this.Cesium.TimeIntervalCollection([
                            new this.Cesium.TimeInterval({start: start_time, stop: end_time}),
                        ]),
                        model: {
                            show: true,
                            uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                            minimumPixelSize: 64, //最小的模型像素
                            maximumScale: 256, //最大的模型像素
                            runAnimations: true, //是否显示动画
                            color: this.Cesium.Color.WHITE, //颜色
                        },
                    }))
                }

            },
            async ok_preview() {
                this.label_data = [];
                this.viewer.dataSources.removeAll()
                const customDataSource = new this.Cesium.CustomDataSource("customDataSource")
                for (let i of this.airport_ids) {
                    if (this.show_tracks) {
                        await this.get_flight_line_data(i, customDataSource)
                    }
                    for (let ii of this.checkbox) {
                        await this.change_checkbox(i, ii, customDataSource)
                    }
                }
                if (this.show_obstacle) {
                    this.draw_obstacle(customDataSource)
                }
                this.draw_label(customDataSource)
                this.viewer.dataSources.add(customDataSource)
                this.auth_fly && this.viewer.flyTo(customDataSource)
            },

            get_weather() {
                this.weather_info = {
                    aqi: "48",
                    direct: "西南风",
                    humidity: "36",
                    info: "多云",
                    power: "3级",
                    temperature: "18",
                    wid: "01"
                }
                return
                // 获取天气信息
                get_weather({city: this.slide_menu[this.slide_model].main_city}).then(res => {
                    if (res.code) {
                        this.weather_info = res.data;
                    } else {
                        this.$toast({
                            title: this.$t('setting.warning'),
                            type: 'warning',
                            message: res.msg
                        })
                    }
                })
            },
            async change_checkbox(airport_id, type, customDataSource) {
                const res = await this.handle_data({type, air_port: airport_id})

                this.draw(res, customDataSource)
            },
            draw_obstacle(customDataSource) {
                // if it's empty data , it will be reutrned directly
                if (!this.obstacle_data.length) return;

                for (let i of this.obstacle_data) {
                    // deep copy object
                    const every_obstacle_data = JSON.parse(JSON.stringify(i))
                    every_obstacle_data.latlng.push(every_obstacle_data.latlng[0],every_obstacle_data.latlng[1] )
                    every_obstacle_data.max_height.push(every_obstacle_data.max_height[0])
                    every_obstacle_data.min_height.push(every_obstacle_data.min_height[0])
                    // every_obstacle_data.push(every_obstacle_data[every_obstacle_data.length - 1])
                    customDataSource.entities.add({
                        wall: {
                            show: true,
                            positions: this.Cesium.Cartesian3.fromDegreesArray(every_obstacle_data.latlng),
                            minimumHeights: every_obstacle_data.min_height,
                            maximumHeights: every_obstacle_data.max_height,
                            material: this.Cesium.Color.RED
                        }
                    })
                }
            },
            draw_label(customDataSource) {
                for (let i of this.label_data) {
                    // show label info
                    customDataSource.entities.add({
                        // setting position, global use Cesium, so there is no need to use `this` key word
                        position: this.Cesium.Cartesian3.fromDegrees(i.lng,i.lat, 10000),
                        // position: this.Cesium.Cartesian3.fromDegrees(122.62168346273343, 31.685898240651888,  10000),
                        billboard: new this.Cesium.BillboardGraphics({
                            show: true,
                            // the data source is saved in Tencent cloud
                            image: 'https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/cesium_info_bg.png',
                            scale: .1,
                        }),
                        label: {
                            show: true,
                            text: i.label,
                            font: 'font-size: 12px;',
                            scale: .7
                        }
                    })
                }
            },
            draw(data, customDataSource) {
                const start_time = this.Cesium.JulianDate.fromDate(new Date('2021-10-10 10:00:00'));
                const stop_time = this.Cesium.JulianDate.fromDate(new Date('2021-10-10 10:10:00'));
                if (!this.show_tracks) {
                    this.viewer.clock.startTime = start_time.clone()
                    this.viewer.clock.currentTime = start_time.clone()
                    this.viewer.clock.stopTime = stop_time.clone()
                    this.viewer.timeline.zoomTo(start_time.clone(), stop_time.clone());// 循环执行,即为2，到达终止时间，重新从起点时间开始
                    this.viewer.clock.clockRange = this.Cesium.ClockRange.LOOP_STOP;
                    this.viewer.clock.multiplier = 50
                }




                // const customDataSource = new this.Cesium.CustomDataSource("customDataSource")
                const material_color = this.Cesium.Color.fromRandom({alpha: .5});
                // 动画总时间
                const count_time = 800;
                for (let i in data) {
                    const property = new this.Cesium.SampledPositionProperty();
                    // 计算出每一个节点用时多少
                    const item_time = count_time / (data[i].position.length / 2)
                    property.addSample(start_time, this.Cesium.Cartesian3.fromDegrees(data[i].position[0], data[i].position[1], data[i].max_height[0]))
                    for (let index = 1; index < data[i].position.length / 2; index++) {
                        property.addSample(this.Cesium.JulianDate.addSeconds(start_time, index * item_time, new this.Cesium.JulianDate()), this.Cesium.Cartesian3.fromDegrees(data[i].position[index * 2], data[i].position[index * 2 + 1], data[i].max_height[index]))
                    }


                    const orientation = new this.Cesium.VelocityOrientationProperty(property)
                    // customDataSource.entities.add(new this.Cesium.Entity({
                    //     position: this.Cesium.Cartesian3.fromDegrees(data[i].position[0], data[i].position[1], 10000),
                    //
                    //     billboard: new this.Cesium.BillboardGraphics({
                    //         show: true,
                    //         image: 'https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/cesium_info_bg.png',
                    //         scale: .1,
                    //
                    //     }),
                    //     label: {
                    //         show: Boolean(data[i].label),
                    //         text: data[i].label,
                    //         font: 'font-size: 12px;',
                    //         scale: .7
                    //     }
                    // }))


                    customDataSource.entities.add(new this.Cesium.Entity({
                        // position: this.Cesium.Cartesian3.fromDegrees(data[i].position[0], data[i].position[1], 10000),

                        position: property,
                        availability: new this.Cesium.TimeIntervalCollection([new this.Cesium.TimeInterval({
                            start: start_time,
                            // stop: this.Cesium.JulianDate.fromDate(new Date('2021-11-01 00:10:00'))
                            stop: stop_time
                        })]),
                        orientation: orientation,
                        model: {
                            show: this.show_aircraft,
                            uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                            minimumPixelSize: 64, //最小的模型像素
                            maximumScale: 528, //最大的模型像素
                            runAnimations: true, //是否显示动画
                            clampAnimations: true, //是否保持最后一针的动画
                            color: this.Cesium.Color.WHITE, //颜色
                        },
                        wall: {
                            show: true,
                            positions: this.Cesium.Cartesian3.fromDegreesArray(data[i].position),
                            maximumHeights: data[i].max_height,
                            minimumHeights: data[i].min_height,
                            // maximumHeights: Array(data[i].position.length / 2).fill(10000),
                            // minimumHeights: Array(data[i].position.length / 2).fill(0),
                            material: material_color
                        },
                    }))
                }

            },
            async init() {
                const viewer = new this.Cesium.Viewer('cesiumContainer', {
                    shouldAnimate: true,// 自动播放
                    terrainProvider: this.Cesium.createWorldTerrain(),
                })
                this.viewer = viewer


                // this.Cesium.ShadowMap({
                //     lightCamera: new this.Cesium.Camera(this.viewer.scene),
                //     pointLightRadius: 100,
                //     darkness: .3
                // })

                this.viewer._cesiumWidget._creditContainer.style.display = 'none'
                // viewer.animation.container.style.visibility = 'hidden'
                const start_time = this.Cesium.JulianDate.fromDate(new Date('2021-11-01 00:00:00'));
                viewer.clock.startTime = start_time
                viewer.clock.currentTime = start_time
                viewer.clock.stopTime = this.Cesium.JulianDate.fromDate(new Date('2021-11-01 23:59:59'))
                this.viewer.timeline.zoomTo(start_time, this.Cesium.JulianDate.fromDate(new Date('2021-11-01 23:59:59')));// 循环执行,即为2，到达终止时间，重新从起点时间开始
                viewer.clock.clockRange = this.Cesium.ClockRange.LOOP_STOP;
                viewer.clock.multiplier = 50

                // TODO =============此段代码 测试 空域 可以删==================
                // viewer.entities.add({
                //     wall: {
                //         show: true,
                //         positions: this.Cesium.Cartesian3.fromDegreesArray(this.air_port_space_data),
                //         minimumHeights: Array(this.air_port_space_data.length / 2).fill(0),
                //         maximumHeights: Array(this.air_port_space_data.length / 2).fill(100000),
                //         material: this.Cesium.Color.RED
                //     }
                // })
                // TODO ====================测试空域代码结束===================



                //lng = 90 = 31.166750
                //lat = 78 = 121.783333
                // 设置home默认位置
                this.Cesium.Camera.DEFAULT_VIEW_RECTANGLE =
                    this.Cesium.Rectangle.fromDegrees(
                        121.8,
                        31.136750,
                        121.8,
                        31.136750
                    ); //home定位到范围
                // 设置home默认视口大小
                this.Cesium.Camera.DEFAULT_VIEW_FACTOR = 0.0031;

                // 获取航路数据
                const res = await get_air_route_data({pagesize: 9999})

                // 等待处理的数据
                const await_enter_handle_data = {}
                const await_leave_handle_data = {}

                // 整理等待处理的数据
                for (let i of res.results) {
                    if (i.type === 0) {
                        if (i.group in await_enter_handle_data) {
                            await_enter_handle_data[i.group].push(i)
                        } else {
                            await_enter_handle_data[i.group] = [i]
                        }
                    } else {
                        if (i.group in await_leave_handle_data) {
                            await_leave_handle_data[i.group].push(i)
                        } else {
                            await_leave_handle_data[i.group] = [i]
                        }
                    }
                }

                // 对数据进行排序 并 整理
                let data = {}
                for (let i in await_enter_handle_data) {
                    await_enter_handle_data[i].sort((a, b) => (a.order - b.order))
                    for (let ii of await_enter_handle_data[i]) {
                        if (i in data) {
                            data[i].position.push(parseFloat(ii.lng), parseFloat(ii.lat))
                            data[i].max_height.push(parseFloat(ii.max_height))
                            data[i].min_height.push(parseFloat(ii.min_height))
                        } else {
                            data[i] = {
                                position: [parseFloat(ii.lng), parseFloat(ii.lat)],
                                min_height: [parseFloat(ii.min_height)],
                                max_height: [parseFloat(ii.max_height)]
                            }
                        }
                    }
                }

                this.enter_data = data

                data = {}
                for (let i in await_leave_handle_data) {
                    await_leave_handle_data[i].sort((a, b) => (a.order - b.order))
                    for (let ii of await_leave_handle_data[i]) {
                        if (i in data) {
                            data[i].position.push(parseFloat(ii.lng), parseFloat(ii.lat))
                            data[i].max_height.push(parseFloat(ii.max_height))
                            data[i].min_height.push(parseFloat(ii.min_height))
                        } else {
                            data[i] = {
                                position: [parseFloat(ii.lng), parseFloat(ii.lat)],
                                min_height: [parseFloat(ii.min_height)],
                                max_height: [parseFloat(ii.max_height)]
                            }
                        }
                    }
                }

                this.leave_data = data

                this.checkbox = [0]


                // this.draw(this.leave_data)

            },
            async init_enter_leave_optimization_airspace_data() {
                await get_enter_leave_optimization_airspace_data({pagesize: 9999}).then(result => {
                    const space_data = result.results.filter(x => x.type === 0)
                    const obstacle_data = result.results.filter(x => x.type === 1)
                    this.air_port_space_data = space_data.map(x => [parseFloat(x.lng), parseFloat(x.lat)]).flat()
                    this.air_port_space_data.push(this.air_port_space_data[0], this.air_port_space_data[1])
                    // first create a variable holds obstacle data
                    const obstacle_group_obj = {}
                    // classify obstacles data
                    for(let i of obstacle_data) {
                        if (i.group in obstacle_group_obj) {
                            // if in the variable, direct add it
                            obstacle_group_obj[i.group].push(i)
                        }else {
                            // if not in the variable, create this element and then add
                            obstacle_group_obj[i.group] = [i]
                        }
                    }
                    // convert object to array
                    const obstacle_group_array = []
                    for (let i in obstacle_group_obj) {
                        obstacle_group_array.push({
                            latlng: obstacle_group_obj[i].map(x => [parseFloat(x.lng), parseFloat(x.lat)]).flat(),
                            max_height: obstacle_group_obj[i].map(x => parseFloat(x.max_height) * 1852),
                            min_height: obstacle_group_obj[i].map(x => parseFloat(x.min_height) * 1852),
                        })
                    }
                    // show obstacle data, it can be deleted later
                    // then add the obstacles data to this.data
                    this.obstacle_data = obstacle_group_array
                })
                return this.air_port_space_data
            },
            async handle_data(params) {

                const temp_data = await get_air_route_data({pagesize: 9999,...params})
                const await_enter_handle_data = {}

                // 整理等待处理的数据
                for (let i of temp_data.results) {
                    if (i.label) {
                        this.label_data.push({
                            lat: parseFloat(i.lat),
                            lng: parseFloat(i.lng),
                            label: i.label,
                            height: parseFloat(i.max_height)
                        })
                    }
                    if (i.group in await_enter_handle_data) {
                        await_enter_handle_data[i.group].push(i)
                    } else {
                        await_enter_handle_data[i.group] = [i]
                    }
                }
                // 对数据进行排序 并 整理
                let data = {}
                for (let i in await_enter_handle_data) {
                    await_enter_handle_data[i].sort((a, b) => (a.order - b.order))
                    for (let ii of await_enter_handle_data[i]) {
                        if (i in data) {
                            data[i].position.push(parseFloat(ii.lng), parseFloat(ii.lat))
                            data[i].max_height.push(parseFloat(ii.max_height))
                            data[i].min_height.push(parseFloat(ii.min_height))
                        } else {
                            data[i] = {
                                position: [parseFloat(ii.lng), parseFloat(ii.lat)],
                                min_height: [parseFloat(ii.min_height)],
                                max_height: [parseFloat(ii.max_height)],
                            }
                        }
                    }
                }

                return data
            }

        },
        async mounted() {
            const closeLoading = this.$loading()
            this.get_weather()
            this.layout = this.$route.meta.use_layout
            // 获取进离场航路优化的机场数据
            get_enter_leave_class_data().then(res => {
                this.slide_menu = res.results
            }).finally(()=>{
                closeLoading()
            })
            await this.init_enter_leave_optimization_airspace_data()
            this.$nextTick(() => {
                this.init()
            })
        },
        computed: {
            current_child_city() {
                if (this.slide_menu[this.slide_model])
                    return this.slide_menu[this.slide_model].child;
                return []
            }
        }
    }
</script>
<style lang="stylus" scoped>
    .container__options
        position: absolute
        top: 95px
        left 340px
        width 200px
        /*height 150px*/
        background var(--v-secondary-base)
        opacity .5
        box-shadow 0px 0px 10px #888
        padding 10px
        border-radius 3px
        transition ease .2s all
        &:hover
            opacity: 1;
            transition ease .2s all

</style>
